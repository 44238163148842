import { LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { DecimalPipe, registerLocaleData } from '@angular/common';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { SimpleStorageService } from './services/simple.storage.service';

import { defineLocale } from 'ngx-bootstrap/chronos';
import { itLocale } from 'ngx-bootstrap/locale';
defineLocale('it', itLocale);
import localeIt from '@angular/common/locales/it';

import { ErrorInterceptorService } from './services/error.interceptor.service';
import { AuthGuard } from './auth/auth-guard.service';
import { UserService } from './services/user.service';

registerLocaleData(localeIt);

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    AppRoutingModule,
    BsDatepickerModule.forRoot(),
  ],
  providers: [
    SimpleStorageService,
    HttpClient,
    AuthGuard,
    ErrorInterceptorService,
    UserService,
    DecimalPipe,
    {provide: LOCALE_ID, useValue: 'it-it' },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptorService, multi: true },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
