import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})

export class SimpleStorageService {

  constructor() { }

  protected memStorage: any = {};

  public localStorage(): any {
    return window.localStorage;
  }

  public sessionStorage(): any {
    return window.sessionStorage;
  }

  public credentialStorage(): any {
    return this.sessionStorage;
  }

  getItem(key: string): any {
    if (typeof this.sessionStorage()[key] === 'undefined') {
      return null;
    } else {
      return this.sessionStorage()[key];
    }
  }

  setItem(key: string, value: any): any {
    if (this.sessionStorage()[key] === undefined || this.sessionStorage()[key] !== value) {
      this.sessionStorage()[key] = value;
    }
  }
  removeItem(key: string): any {
    if (this.sessionStorage()[key] !== undefined) {
      delete this.sessionStorage()[key];
    }
  }

  clearAll(): any {
    this.sessionStorage().clear();
  }

}

