import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { AuthService } from '../services/auth.service';



@Injectable()
export class AuthGuard implements CanActivate {

    constructor(private router: Router, private authService: AuthService) {
    }

    // public canActivate() {
    //   return this.authService.isAuthenticated()
    //     .pipe(
    //         tap(authenticated => {
    //         if (!authenticated) {
    //             this.router.navigate(['auth/login']);
    //         }
    //         }),
    //     );
    // }

    public canActivate(
      next: ActivatedRouteSnapshot,
      state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
      let url: string = state.url;
      return this.checkUserLogin(next, url);
    }

    checkUserLogin(route: ActivatedRouteSnapshot, url: any): boolean {
      if (this.authService.isAuthenticated()) {
        const userRoles = this.authService.getRoles();
        if (route.data.role) {
          const found = route.data.role.some((r: string)=> userRoles.includes(r));
          return found;
        } else {
          return true;
        }
      } else {
        this.router.navigate(['/auth/login']);
        return false;
      }
    }
  }
