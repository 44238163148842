<div class="container-fluid">
  <div class="row">
    <div class="col-12">
      <router-outlet></router-outlet>
    </div>
  </div>
  <div class="row">
    <div class="col-12">
      <div class="alert alert-light mt-2" role="alert">
        <div class="d-flex align-items-center justify-content-between">
          <div class="d-flex align-items-center">
            <h6 class="m-0"><i class="fas fa-lg fa-question-circle"></i> Non riesci a vedere il contenuto della pagina?</h6>
            <button type="button" (click)="openAlert()" class="btn btn-light ml-3 px-4">
              {{alert ? 'Chiudi' : 'Apri'}} <i class="ml-1 fas" [ngClass]="{'fa-chevron-down': !alert, 'fa-chevron-up': alert }"></i>
            </button>
          </div>
          <div>
            <button type="button" class="btn btn-light ml-3 px-4" (click)="logout()">Logout</button>
          </div>
        </div>
        <div class="alert-inner col-12 mt-4" *ngIf="alert">
          <p>Se utilizzi Google Chrome è necessario dare il consenso all'utilizzo dei cookies cliccando in alto sull'icona con l'occhio barrato, su <strong>"Il sito non funziona?"</strong> e su <strong>"Consenti Cookies"</strong> (vedi screenshot). Utilizziamo i cookies esclusivamente per salvare i dati dell'Agente e dell'Agenzia sul tuo browser, i dati indicati <strong>non vengono in nessun modo memorizzati nè archiviati da terze parti</strong>. Se vuoi eliminare i dati salvati sul browser è sufficiente cancellare i dati di navigazione.</p>
          <img class="img-fluid mb-2" src="../assets/img/FIAIP-cookies1.jpg" alt="cookie consent">
          <img class="img-fluid" src="../assets/img/FIAIP-cookies2.jpg" alt="cookie consent">
        </div>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-12 text-center">
      <p class="small copyright mb-2">&copy; {{thisYear}} - Rent v.{{version}} - developed with <i class="fas fa-skull-crossbones"></i> by <a href="https://piratiassociati.com" target="blank">Pirati Associati</a></p>
    </div>
  </div>
</div>