import { LOCALE_ID, NgModule } from '@angular/core';
import { ExtraOptions, RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './auth/auth-guard.service';


const routes: Routes = [
  {
    path: 'form',
    canActivate: [AuthGuard],
    loadChildren: () => import('./form/form.module')
      .then(m => m.FormModule),
  },
  {
    path: 'auth',
    // canActivate: [AuthGuard],
    loadChildren: () => import('./auth/auth.module').then(m => m.AuthModule),
  },
  { path: '', redirectTo: 'form', pathMatch: 'full' },
  { path: '**', redirectTo: 'form' },
];

const config: ExtraOptions = {
  useHash: false,
};


@NgModule({
  imports: [RouterModule.forRoot(routes, config)],
  exports: [RouterModule],
  providers: [
    {provide: LOCALE_ID, useValue: 'it-it' }
  ]
})
export class AppRoutingModule { }
