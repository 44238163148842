import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError, switchMap, tap } from 'rxjs/operators';
import { Router } from '@angular/router';
import { AuthService } from './auth.service';



@Injectable({
  providedIn: 'root'
})
export class ErrorInterceptorService implements HttpInterceptor {  

  constructor(private route : Router, private authService: AuthService) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(catchError(err => {
      
      console.log('******** HTTP ERROR INTERCEPTOR ******* status->', err.status)
      
      if (err.status === 401) {
        if (request.url.endsWith("/auth/refresh")) {
          this.route.navigate(['/auth/login'])
        } else {
          let token = this.authService.getRefreshToken();
          if (token) {
            return this.resolve(token).pipe(
              switchMap(() => {
                const authReq = request.clone({
                  headers: request.headers.set('Authorization', 'Bearer ' + this.authService.getToken())
                });
                return next.handle(authReq)
              })
            )
          }
        }
        
        // this.global.checkLogged();
        // this.route.navigate(['/login']);

      }
      return next.handle(request);

    }))
  }

  resolve(token: string) {
    return this.authService.refresh(token).pipe(tap(response => {
      this.authService.setToken(response.accessToken);                
      this.authService.setTokenExpDate(response.accessTokenExpires);
      this.authService.setRefreshToken(response.refreshToken);
      this.authService.setRefreshTokenExpDate(response.refreshTokenExpires);      
    })
    );
  }
}
