import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

import { User, UserAdminPassword, UserChangePassword, UserWithPass } from '../user.model';
import { AuthService } from './auth.service';

@Injectable()
export class UserService {
  public token: string = '';
  public headers: any;

  public apiURL = environment.apiUrl;

  constructor(private http: HttpClient, private authService: AuthService) {
    this.authService.onTokenChange()
      .subscribe((authToken: string) => {
        if (authToken) {
          this.token = authToken;
          this.headers = { headers: {'Authorization': 'Bearer ' + this.token }};
        }
      });
    this.token = this.authService.getToken();
    this.headers = { headers: { 'Authorization': 'Bearer ' + this.token } };
  }

  // USER

  public getUser(): Promise<User> {
    const promise = this.http.get(this.apiURL + '/user', this.headers).toPromise();
    return promise.then((result: any) => {
      return result;
    }, (error) => {
      console.error('Could not retrieve data from ', this.apiURL, 'due:', error);
      return Promise.reject(error);
    });
  }

  public getUsers(): Promise<User[]> {
    const promise = this.http.get(this.apiURL + '/User', this.headers).toPromise();
    return promise.then((result: any) => {
      return result;
    }, (error) => {
      console.error('Could not retrieve data from ', this.apiURL, 'due:', error);
      return Promise.reject(error);
    });
  }

  public createUser(payload: UserWithPass): Promise<User> {
    const promise = this.http.post(this.apiURL + '/User', payload, this.headers).toPromise();
    return promise.then((result: any) => {
      return result;
    }, (error) => {
      console.error('Could not get data from ', this.apiURL, 'due:', error);
      return Promise.reject(error);
    });
  }

  public editUser(payload: User): Promise<User> {
    const promise = this.http.put(this.apiURL + '/User', payload, this.headers).toPromise();
    return promise.then((result: any) => {
      return result;
    }, (error) => {
      console.error('Could not get data from ', this.apiURL, 'due:', error);
      return Promise.reject(error);
    });
  }

  public changePasswordAdmin(payload: UserAdminPassword): Promise<User> {
    const promise = this.http.put(this.apiURL + '/User/adminChangePasswordForUser', payload, this.headers).toPromise();
    return promise.then((result: any) => {
      return result;
    }, (error) => {
      console.error('Could not get data from ', this.apiURL, 'due:', error);
      return Promise.reject(error);
    });
  }

  public changePassword(payload: UserChangePassword): Promise<User> {
    const promise = this.http.put(this.apiURL + '/User/changePassword', payload, this.headers).toPromise();
    return promise.then((result: any) => {
      return result;
    }, (error) => {
      console.error('Could not get data from ', this.apiURL, 'due:', error);
      return Promise.reject(error);
    });
  }

  // LANG
  // getLangs(): Promise<Lang> {
  //   const promise = this.http.get(this.apiURL + '/Lang').toPromise();
  //   return promise.then((result: any) => {
  //     return result;
  //   }, (error) => {
  //     console.error('Could not retrieve data from ', this.apiURL, 'due:', error);
  //     return Promise.reject(error);
  //   });
  // }

  // getLangbyId(id: string): Promise<Lang> {
  //   const promise = this.http.get(this.apiURL + '/Lang/' + id).toPromise();
  //   return promise.then((result: any) => {
  //     return result;
  //   }, (error) => {
  //     console.error('Could not retrieve data from ', this.apiURL, 'due:', error);
  //     return Promise.reject(error);
  //   });
  // }

}

