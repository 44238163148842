import { Component } from '@angular/core';
import { BsLocaleService } from 'ngx-bootstrap/datepicker';

import packageJson from '../../package.json';
import { AuthService } from './services/auth.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.less']
})
export class AppComponent {
  title = 'fiaip-calc';
  locale = 'it';

  thisYear = new Date().getFullYear();
  public version: string = packageJson.version;

  alert = false;

  constructor(private localeService: BsLocaleService, private authService: AuthService, private router: Router) {
    this.localeService.use(this.locale);
  }

  public openAlert(): void {
    this.alert = !this.alert;
  }

  public logout() {
    this.authService.logout();
    this.router.navigate(['/auth'])
  }
}
