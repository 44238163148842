import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { BehaviorSubject, Observable, of } from 'rxjs';
import { filter, share, map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { SimpleStorageService } from '../services/simple.storage.service';
import { User } from '../user.model';

export interface UserDto{
    username: string;
    password: string;
    clientType: string;
}
@Injectable({
    providedIn: 'root'
})

export class AuthService {
    public apiURL = environment.apiUrl;
    public token: string = '';
    public roleAs: string = '';
    public headers = {};

    protected token$: BehaviorSubject<string> = new BehaviorSubject(this.token);

    constructor(private http: HttpClient, private storage: SimpleStorageService) {
        this.setHeader();
    }

    setHeader() {
        this.token = this.getToken();
        this.headers = { headers: { 'Authorization': 'Bearer ' + this.token } };
    }

    isAuthenticated(): boolean {
        const token = this.getToken();
        // if (token && (new Date() < this.getTokenExpDate())) {
        if (token) {
            return true;
        } else {
            return false;
        }
    }

    getRoles() {
        let user = this.getUser();
        return (user && user.roles) ? user.roles : [];
    }

    public login(payload: UserDto): Promise<any> {
        const promise = this.http.post(this.apiURL + '/auth/authorize', payload).toPromise();
        return promise.then((result: any) => {
          return result;
        }, (error) => {
          console.error('Could not get data from ', this.apiURL, 'due:', error);
          return Promise.reject(error);
        });
    }

    public loginLocal(payload: UserDto): Promise<any> {
        const promise = this.http.post(this.apiURL + '/auth/local/authorize', payload).toPromise();
        return promise.then((result: any) => {
          return result;
        }, (error) => {
          console.error('Could not get data from ', this.apiURL, 'due:', error);
          return Promise.reject(error);
        });
    }

    public refresh(refreshToken: string): Observable<any> {
        this.setHeader();
        const promise = this.http.post(this.apiURL + '/auth/refresh', {refreshToken: refreshToken}, this.headers);
        return promise;
    }

    public logout() {
        this.storage.clearAll();
    }

    public resetPassword() {
    }

    public setToken(token: string) {
        this.storage.setItem('token', token);
        this.token = token;
        this.token$.next(this.token);
    }

    public getToken(): string{
        return this.storage.getItem('token');
    }

    public setTokenExpDate(tokenExpDate: Date): Observable<string>{
        return this.storage.setItem('tokenExpDate', tokenExpDate);
    }

    public getTokenExpDate(): Date {
        return new Date(this.storage.getItem('tokenExpDate'));
    }

    public setRefreshToken(token: string) {
        this.storage.setItem('refreshToken', token);
    }

    public getRefreshToken(): string{
        return this.storage.getItem('refreshToken');
    }

    public setRefreshTokenExpDate(tokenExpDate: Date): Observable<string>{
        return this.storage.setItem('refreshTokenExpDate', tokenExpDate);
    }

    public getRefreshTokenExpDate(): Date {
        return new Date(this.storage.getItem('refreshTokenExpDate'));
    }

    public setUser(user: User) {
        this.storage.setItem('user', JSON.stringify(user));
    }

    public getUser(): User {
        return JSON.parse(this.storage.getItem('user'));
    }

    public setUserRole(userRole: string) {
        this.storage.setItem('userRole', userRole);
    }

    public onTokenChange(): Observable<string>{
        return this.token$
        .pipe(
            filter(value => !!value),
            share(),
        );
    }
    
}